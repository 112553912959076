import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";
import cryptoJS from "crypto-js";
import runDecrypt from "../../container/hash/Decrypt";
import Navbar from "../layout/Navbar";

const LoginMobile = () => {
    const [Tableusername, setTableusername] = useState(null);
    const [Tablepassword, setTablepassword] = useState(null);
    // const [isCodeError, setCodeError] = useState(null);
    // const [errorMessage, setErrorMessage] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [eye, setEye] = useState(false);

    const notif = useSelector(state => state.notif);
    const latitude = useSelector(state => state.latitude);
    const longitude = useSelector(state => state.longitude);

    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [referralCode, setReferralCode] = useState();
    const [tableUser, setTableUser] = useState({});

    const [tableUsername, setTableUsername] = useState('');
    const [tableUserFullName, setTableUserFullName] = useState('');
    const [tableUserEmail, setTableUserEmail] = useState('');
    const [tableUserPhoneCountryCode, setTableUserPhoneCountryCode] = useState('');
    const [tableUserPhoneNumberShort, setTableUserPhoneNumberShort] = useState('');

    const [codePhone, setCodePhone] = useState([]);

    const [errorEmail, setErrorEmail] = useState('');
    const [errorFullName, setErrorFullName] = useState('');
    const [errorPhoneNumber, setErrorPhoneNumber] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        // setBtnLoading(true);

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += Tableusername;
        StringSignature += Tablepassword;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/auth/v01/signin',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                signature : signature
            },
            data : {
                tableusername : Tableusername,
                tableuserpassword : Tablepassword,
                latitude : latitude,
                longitude : longitude,
                tableuserlanguage : 'EN'
            }
        }).then(res => {
            if(res.data.status.status === 0){
                // if(res.data.status.code === '888065165005'){
                //     alert.error(res.data.status.code);
                // }else if(res.data.status.code === '888065220005'){
                //     alert.error(res.data.status.code);
                // }else{
                //     setCodeError(res.data.status.code.substring(0,9));
                //     setErrorMessage(res.data.status.code);
                // }

                res.data.status.message.forEach(element => {
                    switch(element.code.substring(0,9)){
                        case '888005015':
                            setErrorUsername(element.errormassage);
                        break;
                        case '888005115':
                            setErrorPassword(element.errormassage);
                        break;
                        default:
                            alert.error(element.errormassage)
                        break;
                    }
                });

                let inputUsername = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005015');
                let inputPassword = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005115');

                if(inputUsername.length === 0){setErrorUsername('');}
                if(inputPassword.length === 0){setErrorPassword('');}
            }else{
                localStorage.setItem('useruuid', JSON.stringify(res.data.data));
                // setCodeError(null);
                navigate('/home');
            }
            // setBtnLoading(false);
            console.log(res.data);
        }).catch(error => {
            console.log(error);
        })
    }

    const eyeHandle = () => {
        if(eye){
            setEye(false);
        }else{
            setEye(true);
        }
    }

    useEffect(() => {
        if(notif !== ''){
            alert.show(notif);

            dispatch({
                type : 'notif',
                newNotif : ''
            })
        }
    }, [notif, dispatch, alert]);

    const ValidationError = () => {
        let validationError;

        if(errorPassword.substring(0,9) === '888005115'){
            validationError = 'form-control border border-danger';
        }else{
            validationError = 'form-control border-right-none';
        }
        // else if(isCodeError === '888065010'){
        //     validationError = 'form-control border border-danger';
        // }else if(isCodeError === '888065090'){
        //     validationError = 'form-control border border-danger';
        // }else{
        //     validationError = 'form-control border-right-none';
        // }


        return validationError;
    }

    useEffect(() => {
        if(localStorage.getItem('tokenTrans') === null){
            navigate('/');
        }
    }, [])

    useEffect(() => {
        setReferralCode(runDecrypt.runDecodeEncrypt(localStorage.getItem('referralCode')));

        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += localStorage.getItem('tokenTrans');

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/auth/v01/detailid',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                signature : signature
            },
            data : {
                tokentrans : localStorage.getItem('tokenTrans')
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setTableUser(res.data.data);
                setCodePhone(res.data.support);
                setTableUserPhoneCountryCode(res.data.data.tempuserphonecountrycode);
                setTableUserPhoneNumberShort(res.data.data.tempuserphonenumbershort);
            }
        }).catch(error => {
            navigate('/');
            console.log(error);
        })
    }, []);

    const onSubmitRegister = (e) => {
        e.preventDefault();
        // setBtnLoading(true);
        
        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += tableUsername;
        StringSignature += tableUserFullName;
        StringSignature += tableUserEmail;
        StringSignature += tableUserPhoneCountryCode;
        StringSignature += tableUserPhoneNumberShort;
        StringSignature += localStorage.getItem('tokenTrans');

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/auth/v01/store',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                signature : signature
            },
            data : {
                tableusername : tableUsername,
                tableuserfullname : tableUserFullName,
                tableuseremail : tableUserEmail,
                userphonecountrycode : tableUserPhoneCountryCode,
                userphonenumbershort : tableUserPhoneNumberShort,
                tokentrans : localStorage.getItem('tokenTrans'),
            }
        }).then(res => {
            if(res.data.status.status === 1){
                dispatch({
                    type : 'PAGE_SUCCESS',
                    newPageSuccess : 'registrationSuccess'
                });

                localStorage.removeItem('tokenTrans');
                navigate('/success')
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code.substring(0,9)){
                        case '888005035':
                            setErrorEmail(element.errormassage);
                        break;
                        case '888005015':
                            setErrorUsername(element.errormassage);
                        break;
                        case '888005020':
                            setErrorFullName(element.errormassage);
                        break;
                        case '888005060':
                            setErrorPhoneNumber(element.errormassage);
                        break;
                    }
                });

                let inputEmail = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005035');
                let inputUsername = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005015');
                let inputFullName = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005020');
                let inputPhoneNumber = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005060');

                if(inputEmail.length === 0){setErrorEmail('');}
                if(inputUsername.length === 0){setErrorUsername('');}
                if(inputFullName.length === 0){setErrorFullName('');}
                if(inputPhoneNumber.length === 0){setErrorPhoneNumber('');}
            }
        }).catch(error => {
            console.log(error);
        });
        
        // setBtnLoading(false);
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="container mt-100">
                    <div className="card w-569 m-auto shadow">
                        <div className="card-body">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="home" aria-selected="true">Login</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="profile" aria-selected="false">Register</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="home-tab">
                                    <form method="POST" onSubmit={onSubmit}>
                                        <div className="mb-3 mt-3">
                                            <label htmlFor="tableusername" className="form-label text-black">Username</label>
                                            <input type="text" name="tableusername" className={errorUsername.substring(0,9) === '888005015' ? 'form-control border border-danger' : 'form-control'} onChange={(e) => setTableusername(e.target.value)}/>
                                            {errorUsername.substring(0,9) === '888005015' ? <span className="text-danger d-block errorMessage">{errorUsername}</span> : null}
                                        </div>
                                        <label htmlFor="tableuserpassword" className="form-label text-black">Password</label>
                                        <div className="input-group">
                                            <input type={eye ? 'text' : 'password'} className={ValidationError()} autoComplete="on" onChange={(e) => setTablepassword(e.target.value)}/>
                                            <span className="input-group-text bg-none pointer" onClick={eyeHandle}>
                                                {eye ? 
                                                <img src={process.env.PUBLIC_URL+'/images/components/eye.png'} alt="eye.png"></img>:
                                                <img src={process.env.PUBLIC_URL+'/images/components/eye-slash.png'} alt="eye-slash.png"></img> }
                                            </span>
                                        </div>
                                        {errorPassword.substring(0,9) === '888005115' ? <span className="text-danger d-block errorMessage">{errorPassword}</span> : null}
                                        <div className="row pt-2">
                                            <div className="col">
                                                <Link to={`/forgot-password`} className="text-start d-block text-black text-decoration-none">Forgot Password?</Link>
                                            </div>
                                            {/* <div className="col">
                                                <Link to={`/forgot-username`} className="text-end d-block">Forgot Username?</Link>
                                            </div> */}
                                        </div>
                                        <div className="mb-3 mt-4">
                                            {btnLoading ? 
                                                <button className="btn btn-primary btw-150 m-auto d-block" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                </button> :
                                                <button className="btn btn-primary btw-150 m-auto d-block" type="submit">Login</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="profile-tab">
                                    <form method="POST" onSubmit={onSubmitRegister}>
                                        <div className="mb-3 mt-4">
                                            <label htmlFor="referralcode" className="form-label">Referral Code</label>
                                            <input type="text" name="referralcode" className="form-control" defaultValue={referralCode} disabled/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tableuseremail" className="form-label">Email</label>
                                            <input type="text" name="tableuseremail" placeholder="user@xdw.systems" className={ errorEmail.substring(0,9) === '888005035' ? 'form-control border border-danger' : 'form-control'} defaultValue={tableUser.tempuseremail} onChange={(e) => setTableUserEmail(e.target.value)}/>
                                            {errorEmail.substring(0, 9) === '888005035' ? <span className="text-danger d-block text-end errorMessage">{errorEmail}</span> : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tableuserfullname" className="form-label">Full Name</label>
                                            <input type="text" name="tableuserfullname" placeholder="User XDW" className={ errorFullName.substring(0, 9) === '888005020' ? 'form-control border border-danger' : 'form-control'} defaultValue={tableUser.tempuserfullname} onChange={(e) => setTableUserFullName(e.target.value)}/>
                                            <span className="description">(Length must be more than 5 character and must not contain numeric)</span>
                                            {errorFullName.substring(0, 9) === '888005020' ? <span className="text-danger d-block text-end errorMessage">{errorFullName}</span> : null}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="tableusername" className="form-label">Username</label>
                                            <input type="text" name="tableusername" placeholder="xdwuser1" className={ errorUsername.substring(0, 9) === '888005015' ? 'form-control border border-danger' : 'form-control'} defaultValue={tableUser.tempusername} onChange={(e) => setTableUsername(e.target.value)}/>
                                            <span className="description">(Length must be between 5-100 character and must not start with numeric)</span>
                                            {errorUsername.substring(0, 9) === '888005015' ? <span className="text-danger d-block text-end errorMessage">{errorUsername}</span> : null}
                                        </div>
                                        <label htmlFor="tableusername" className="form-label">Phone Number</label>
                                        <div className={errorPhoneNumber.substring(0,9) === '888005060' ? 'input-group border border-danger border-radius-custom' : 'input-group mb-3'}>
                                            <select name="userphonecountrycode" value={tableUserPhoneCountryCode} style={{ width : '15%', border : '1px solid #ced4da', borderTopLeftRadius: '0.375rem', borderBottomLeftRadius: '0.375rem' }} className="text-center"
                                            onChange={(e) => setTableUserPhoneCountryCode(e.target.value)}>
                                                { codePhone.map((result, i) => (
                                                    <option key={i} value={result.tablecountryphonecodephonecode}>+{result.tablecountryphonecodephonecode}</option>
                                                ))}
                                            </select>
                                            <input type="text" name="userphonenumbershort" className="form-control" aria-label="Text input with dropdown button" placeholder="821987654"
                                            onChange={(e) => setTableUserPhoneNumberShort(e.target.value)} value={tableUserPhoneNumberShort !== null ? tableUserPhoneNumberShort : ''}/>
                                        </div>
                                        {errorPhoneNumber.substring(0, 9) === '888005060' ? <span className="text-danger d-block text-end errorMessage">{errorPhoneNumber}</span> : null}
                                        <div className="mb-3 mt-5">
                                            <button type="submit" disabled={btnLoading ? 'disabled' : ''} className="btn btn-primary btw-150 m-auto d-block">Register</button>
                                            <span className="text-center d-block pt-3">Already have an account? <Link to={`/login`} className="text-decoration-none">Sign in</Link></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginMobile;