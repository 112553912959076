import JsonXdw from '../lang/xdw.json';
import CryptoJS from "crypto-js";

export function MultiLanguage(params){
    let getDefaultLangApps = localStorage.getItem('LangApps') ? localStorage.getItem('LangApps') : 'EN';

    let decode = atob(localStorage.getItem('Translation'));
    let decrypt = CryptoJS.AES.decrypt(decode, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8);
    let parseJson = localStorage.getItem('Translation') !== null ? JSON.parse(decrypt) : JsonXdw;
    let getNisarJson = parseJson[getDefaultLangApps][params] ? parseJson[getDefaultLangApps][params] : params;

    return getNisarJson;
}