import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useCallback } from "react";
import { MultiLanguage } from "../../lang/Language";

import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import cryptoJS from "crypto-js";
import axios from "axios";
import ModalPin from "../../modal/ModalPin";
import ModalInformation from "../../modal/ModalInformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const MainWallet = () => {
    const [TableWalletAccount, setTableWalletAccount] = useState('');
    const [ballance, setBallance] = useState(0);
    const [isRedirectTopup, setIsRedirectTopup] = useState(false);
    const [isRedirectTransfer, setRedirectTransfer] = useState(false);
    const [isRedirectWithdraw, setRedirectWithdraw] = useState(false);
    const [modalPin, setModalPin] = useState(false);

    const [modal, setModal] = useState(false);
    const [errorCode, setErrorCode] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [tokenlist, setTokenList] = useState([]);
   
    const dispatch = useDispatch();
    const alert = useAlert();

    const walletLoad = useCallback(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/wallet/v01/info',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature,
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setTableWalletAccount(res.data.data.walletaccount);
                setBallance(res.data.data.ballance);

                dispatch({
                    type : 'XDWBALANCE',
                    newXdwbalance : res.data.data.ballance
                });
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code){
                        case '888999999999':
                            alert.error(MultiLanguage(element.code));
                        break;
                    }
                });

                setModalPin(false);
            }
            
        }).catch(error => {
            console.log(error);
        });
    }, [alert])

    const tokenList = useCallback(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/token/v01/list',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature,
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setTokenList(res.data.data);
            }
            
        }).catch(error => {
            console.log(error);
        });
    }, [alert])

    useEffect(() => {
        walletLoad()
        tokenList();
    }, [walletLoad, tokenList]);

    const generateHandle = (values) => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/wallet/v01/store',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature,
            },
            data : {
                user2fapintoken : values,
            }
        }).then(res => {
            if(res.data.status.status === 1){
                walletLoad();
                setModalPin(false);
                alert.success('Generate Successfuly.');
            }else{
                res.data.status.message.forEach(element => {
                    alert.error(MultiLanguage(element.code));
                });

                setModalPin(false);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const pinHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/wallet/v01/insertnew',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature,
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setModalPin(true)
            }else{
                res.data.status.message.forEach(element => {
                    setModal(true);
                    setErrorCode('Warning!');
                    setDescriptionError(MultiLanguage(element.code));
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        dispatch({
            type : 'BOX_ACCOUNT',
            newNavAccount : false
        });
    }, []);

    const topupHandle = () => {
        setIsRedirectTopup(true);
    }

    const transferHandle = () => {
        setRedirectTransfer(true);
    }

    const withdrawHandle = () => {
        setRedirectWithdraw(true);
    }

    if(isRedirectTopup){
        return <Navigate to={`/topup`}/>
    }

    if(isRedirectTransfer){
        return <Navigate to={`/transfer`}/>
    }

    if(isRedirectWithdraw){
        return <Navigate to={`/withdraw`}/>
    }

    let resultTokenList = tokenlist.map((result, key) => {
        return(
            <div className="token-list mb-3" key={key}>
                <div className="box-token-list">
                    <div className="row">
                        <div className="col-md-1 mb-2">
                            <img src={process.env.PUBLIC_URL+'/images/components/Image-2.png'} alt="Image.png" width="80" className="icontokenlist"/>
                        </div>
                        <div className="col-md">
                            <div className="pt-2">
                                <h5>{result.token_name}</h5>
                                <p className="text-success">{ result.token_account }</p>
                            </div>
                        </div>
                        <div className="col text-end">
                            <Link to={`/detailtoken/${result.token_index}`} className="text-decoration-none text-black text-size-detail d-block pt-6"><span className="pr-6 block">{MultiLanguage('TOKEN_DETAILS')}</span> <FontAwesomeIcon icon={faAngleRight}/></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    { tokenlist.length > 0 ? <>
                    <div className="main-wallet mt-5">
                        <h3 className="text-primary mb-3">{MultiLanguage('MAIN_WALLET')}</h3>
                        <div className="row">
                            <div className="col mainWallet">
                                <div className="card">
                                    <div className="card-body p-5">
                                        <div className="row">
                                            <h5>{MultiLanguage('MAIN_WALLET')}</h5>
                                            <div className="col">
                                                <p className="pt-2">{TableWalletAccount}</p>
                                            </div>
                                            {/* <div className="col">
                                                { TableWalletAccount === '' ?
                                                <button className="btn btn-outline-info float-right-xdw" onClick={pinHandle}>Generate</button> : '' }
                                            </div> */}
                                        </div>
                                        <div className="pt-1">
                                            <h5>{MultiLanguage('BALANCE_MAIN_WALLET')}</h5>
                                            <p>{ballance} XDW</p>
                                        </div>
                                        <div className="pt-1">
                                            {/* <p className="text-danger">This amount of XDW is used only for topup DWA in wallet associate token.</p> */}
                                            <p className="text-danger">{MultiLanguage('DESCRIPTION_MAINWALLET_INFO')}</p>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm cmb-2">
                                                <button className="btn btn-primary w-100" onClick={topupHandle}>{MultiLanguage('BTN_TOPUP')}</button>
                                            </div>
                                            {/* <div className="col-sm cmb-2">
                                                <button className="btn btn-primary w-100" onClick={withdrawHandle}>Withdraw</button>
                                            </div> */}
                                            {/* <div className="col-sm">
                                                <button className="btn btn-primary w-100" onClick={transferHandle}>Transfer</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col mb-4">
                                <div className="card">
                                    <div className="card-body p-5">
                                        <h5>Saldo</h5>
                                        <p>{ballance} XDW</p>
                                        <div className="pt-4">
                                            <p className="text-danger">
                                                This amount of DWA is used only for token
                                                creating and transaction fee of tokens created
                                                under your account.
                                            </p>
                                        </div>
                                        <button className="btn btn-success w-100 mt-46" onClick={topupHandle}>Top Up</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="main-wallet mt-4 mb-5">
                        <h3 className="text-primary">{MultiLanguage('TOKEN_LIST')}</h3>

                        <div className="mt-3 mb-5">
                            {resultTokenList}
                        </div>
                    </div></> : <div className="main-wallet"><img src={process.env.PUBLIC_URL+'/images/components/loadbars.gif'} width="100%" height="150px"/></div> }

                    <ModalInformation 
                        show={modal} 
                        onHide={() => setModal(false)}
                        errorCode={errorCode}
                        description={descriptionError}
                    />
                    
                    <ModalPin 
                        show={modalPin} 
                        onHide={() => setModalPin(false)}
                        executePin={generateHandle}
                    />
                </div>
            </div>
        </>
    );
}

export default MainWallet;