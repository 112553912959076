import { Link, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAngleDown, faGlobe, faBars } from '@fortawesome/free-solid-svg-icons';

import { useDispatch, useSelector } from "react-redux";
import NavContent from './navcontent/NavContent';
import { useEffect } from 'react';
import NavContentMobile from './navcontent/navContentMobile';
import { MultiLanguage } from "../lang/Language";

const Navbar = () => {
    let profile = JSON.parse(localStorage.getItem('useruuid'));

    const navNotification = useSelector(state => state.navNotification);
    const navAccount = useSelector(state => state.navAccount);
    const navTranslation = useSelector(state => state.navTranslation);

    const dispatch = useDispatch();
    let navigate = useNavigate(); 

    const notificationHandle = () => {
        if(navNotification === false){
            dispatch({
                type : 'BOX_NOTIFICATION',
                newNavNotification : true
            });

            document.getElementById("btnAccount").disabled = true;
            document.getElementById("btnTranslation").disabled = true;

            document.getElementById("btnAccount").style.pointerEvents = "none";
            document.getElementById("btnTranslation").style.pointerEvents = "none";

            document.getElementById("backdrop").style.display = "block";
        }else{
            dispatch({
                type : 'BOX_NOTIFICATION',
                newNavNotification : false
            });
            document.getElementById("btnAccount").disabled = false;
            document.getElementById("btnTranslation").disabled = false;

            document.getElementById("btnAccount").style.pointerEvents = "";
            document.getElementById("btnTranslation").style.pointerEvents = "";

            document.getElementById("backdrop").style.display = "none";
        }
    }

    const accountHandle = () => {
        if(navAccount === false){
            dispatch({
                type : 'BOX_ACCOUNT',
                newNavAccount : true
            });
            document.getElementById("btnNotification").disabled = true;
            document.getElementById("btnTranslation").disabled = true;

            document.getElementById("btnNotification").style.pointerEvents = "none";
            document.getElementById("btnTranslation").style.pointerEvents = "none";

            document.getElementById("backdrop").style.display = "block";
        }else{
            dispatch({
                type : 'BOX_ACCOUNT',
                newNavAccount : false
            });
            document.getElementById("btnNotification").disabled = false;
            document.getElementById("btnTranslation").disabled = false;

            document.getElementById("btnNotification").style.pointerEvents = "";
            document.getElementById("btnTranslation").style.pointerEvents = "";

            document.getElementById("backdrop").style.display = "none";
        }
    }

    const translationHandle = () => {
        if(navTranslation === false){
            dispatch({
                type : 'BOX_TRANSLATION',
                newNavTranslation : true
            });

            if(localStorage.getItem('useruuid')){
                document.getElementById("btnNotification").disabled = true;
                document.getElementById("btnAccount").disabled = true;
    
                document.getElementById("btnNotification").style.pointerEvents = "none";
                document.getElementById("btnAccount").style.pointerEvents = "none";
    
                document.getElementById("backdrop").style.display = "block";
            }
        }else{
            dispatch({
                type : 'BOX_TRANSLATION',
                newNavTranslation : false
            });

            if(localStorage.getItem('useruuid')){
                document.getElementById("btnNotification").disabled = false;
                document.getElementById("btnAccount").disabled = false;
    
                document.getElementById("btnNotification").style.pointerEvents = "";
                document.getElementById("btnAccount").style.pointerEvents = "";
    
                document.getElementById("backdrop").style.display = "none";
            }
        }
    }

    useEffect(() => {
        document.getElementById("backdrop").style.display = "none";
    }, []);

    const transactionHistoryHandle = () => {
        let path='/transactionhistory';
        navigate(path);
    }

    const barsHandle = () => {
        document.getElementById("sidebar").style.display = "block";
    }

    const cancelHandle = () => {
        document.getElementById("btnAccount").disabled = false;
        document.getElementById("btnAccount").style.pointerEvents = "";

        document.getElementById("btnNotification").disabled = false;
        document.getElementById("btnNotification").style.pointerEvents = "";

        document.getElementById("btnTranslation").disabled = false;
        document.getElementById("btnTranslation").style.pointerEvents = "";

        dispatch({
            type : 'BOX_NOTIFICATION',
            newNavNotification : false
        });

        dispatch({
            type : 'BOX_TRANSLATION',
            newNavTranslation : false
        });

        dispatch({
            type : 'BOX_ACCOUNT',
            newNavAccount : false
        });

        document.getElementById("backdrop").style.display = "none";
    }

    return(
        <>
            <div className="backdrop" id="backdrop" onMouseMove={cancelHandle}></div>
            <div className="navbar">
                {localStorage.getItem('useruuid') === null ? 
                <div className="navbar-brand">
                    <Link to={`/`}>
                        <img src={process.env.PUBLIC_URL+'/images/components/237567@2x.png'} alt="brand" className="logo-desktop"></img>
                        <img src={process.env.PUBLIC_URL+'/images/components/logo-circle.png'} alt="brand" className="logo-mobile"></img>
                    </Link>
                </div> : 
                <div className="navbar-brand">
                    <FontAwesomeIcon icon={faBars} className="bars" onClick={barsHandle}/>
                    <Link to={`/`}>
                        <img src={process.env.PUBLIC_URL+'/images/components/logo-circle.png'} alt="brand" className="logo-mobile"></img>
                    </Link>
                </div>
                }
                <div className="navbar-content">
                    { localStorage.getItem('useruuid') === null ? <>
                    <Link to={'/login'} className="btn btn-primary btw-100 mr-20 btnLogin">{MultiLanguage('BTN_LOGIN')}</Link>
                    {/* <Link to={'/new-register'} className="btn btn-outline-primary btw-100 mr-20 btnRegister">Register</Link> */}
                    <Link to={'/login'}><img src={process.env.PUBLIC_URL+'/images/components/person-circle.svg'} alt="person.circle.svg" className="person"/></Link></> : <>
                    <button className="btn btn-primary btw-200 mr-50 m-auto desktopTransaction" onClick={transactionHistoryHandle}>{MultiLanguage('TRANSACTION_HISTORY')}</button>
                    <button className="position-relative mr-50 btn-xdw m-auto mobileTransaction" onClick={transactionHistoryHandle}>
                        <img src={process.env.PUBLIC_URL+"/images/components/History-Transaction.png"} alt="History-Transaction.png" width="21"/>
                    </button>
                    <button type="button" className="position-relative mr-50 btn-xdw m-auto" id="btnNotification" onClick={notificationHandle}>
                        <FontAwesomeIcon icon={faBell} size="lg" className="pt-2"/>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            1<span className="visually-hidden">unread messages</span>
                        </span>
                    </button>
                    <button className="btn btn-light btw-200 mr-50 nmr m-auto" id="btnAccount" onClick={accountHandle}>
                        { profile.tableusername }
                        <FontAwesomeIcon icon={faAngleDown} className="pl-10"/>
                    </button>
                    </>}
                    <button className="btn-xdw m-auto" id="btnTranslation">
                        <FontAwesomeIcon icon={faGlobe} size="lg" className="mr-50 pointer desktopTranslation" onClick={translationHandle}/>
                        <FontAwesomeIcon icon={faGlobe} size="2x" className="mr-50 pointer mobileTranslation" onClick={translationHandle}/>
                    </button>
                </div>
                <div className="nav-list-content listContentDesktop">
                    <NavContent/>
                </div>
                <div className="nav-list-content listContentMobile">
                    <NavContentMobile/>
                </div>
            </div>
        </>
    );
};

export default Navbar