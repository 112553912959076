const Translation = () => {
    const translationHandle = (params) => {
        localStorage.setItem('LangApps', params);
        window.location.reload();
    }
    return(
        <>
            <div className="card list-translation">
                <div className="card-body">
                    <div className="translation">
                        <img src="https://flagcdn.com/w40/us.png" alt="us" className="img-radius" onClick={() => translationHandle('EN')}/>
                        <img src="https://flagcdn.com/w40/id.png" alt="id" className="img-radius" onClick={() => translationHandle('ID')}/>
                        {/* <img src="https://flagcdn.com/w40/cn.png" alt="cn" className="img-radius" onClick={() => translationHandle('CN')}/> */}
                        {/* <img src="https://flagcdn.com/w40/tw.png" alt="id" className="img-radius" onClick={() => translationHandle('TW')}/> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Translation;