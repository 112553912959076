import { useRef } from 'react';
import PinInput from 'react-pin-input';

import Modal from 'react-bootstrap/Modal';

const ModalPin = (props) => {
    let ele = useRef();

    const completeHandle = (value) => {
        ele.clear();
        props.executePin(value);
    }
    return(
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Body className="pt-5 ps-5 pe-5">
                    <h4 className="text-center pb-3">Enter Your PIN</h4>
                    <PinInput 
                        length={6} 
                        secret 
                        type="numeric" 
                        inputMode="number"
                        style={{padding: '10px'}}  
                        onComplete={(value, index) => { completeHandle(value) }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        ref={(n) => (ele = n)}
                    />
                </Modal.Body>
                <Modal.Footer className="border-top-0 pl-44 pr-44 pb-5" style={{display: 'list-item', listStyle: 'none'}}></Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalPin;