import { useState, useEffect } from "react";

import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import SweetPagination from "sweetpagination";
import cryptoJS from "crypto-js";
import axios from "axios";
import { MultiLanguage } from "../../lang/Language";

// const simpledata = JSON.stringify([
//     {
//         'historydata' : 'transaction 1',
//         'status' : 'Completed'
//     },
//     {
//         'historydata' : 'transaction 2',
//         'status' : 'Queueing'
//     },
//     {
//         'historydata' : 'transaction 3',
//         'status' : 'Confirmed'
//     },
//     {
//         'historydata' : 'transaction 4',
//         'status' : 'Completed'
//     },
//     {
//         'historydata' : 'transaction 5',
//         'status' : 'Queueing'
//     },
//     {
//         'historydata' : 'transaction 6',
//         'status' : 'Confirmed'
//     },
// ]);

const TransactionHistory = () => {
    const [currentPageData, setCurrentPageData] = useState([]);
    const [dataSimple, setDatSimple] = useState([]);

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'GET',
            url : process.env.REACT_APP_APIHOST+'/api/transaction/v01/history',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setDatSimple(res.data.data);
            }
        });

        // setDatSimple(JSON.parse(simpledata));
    }, []);

    const statusHistory = (item) => {
        switch(item.status){
            case 'UNPAID':
                return <span class="badge text-bg-warning">{item.status}</span>
            case 'PAID':
                return <span class="badge text-bg-primary">{item.status}</span>
            case 'CONFORM':
                return <span class="badge text-bg-info">{item.status}</span>
            case 'SUCCESS':
                return <span class="badge text-bg-success">{item.status}</span>
            default:
                return <span class="badge text-bg-danger">{item.status}</span>
        }
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <h3 className="text-center text-primary">{MultiLanguage('TRANSACTION_HISTORY')}</h3>
                    { dataSimple.length > 0 ? <>
                    <div className="mt-5 transaction shadow">
                        <table id="transaction-history">
                            <tr>
                                <th>{MultiLanguage('TABLE_HISTORY_TRANSACTION')}</th>
                                <th>{MultiLanguage('TABLE_STATUS_TRANSACTION')}</th>
                            </tr>
                            {currentPageData.map((item, key) => (
                                <tr key={key}>
                                    <td>{item.description} - {new Date(item.timestamp).toLocaleDateString()}</td>
                                    <td width={150}>
                                        {statusHistory(item)}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                    <div className="mt-5">
                        <SweetPagination
                            currentPageData={setCurrentPageData}
                            getData={dataSimple}
                            dataPerPage={5}
                            navigation={true}
                            getStyle={'style-2'}
                        />
                    </div>
                    </> : <div className="mt-5 transaction shadow"><img src={process.env.PUBLIC_URL+'/images/components/loadbars.gif'} width="100%" height="150px"/></div>}
                </div>
            </div>
        </>
    );
}

export default TransactionHistory;