import { Link } from 'react-router-dom';
import { MultiLanguage } from '../../../lang/Language';

const Account = () => {
    let params = window.location.pathname;
    
    return(
        <>  
            { localStorage.getItem('useruuid') !== null ? 
            <div className="card account">
                <div className="card-body">
                    <div className="minus-ml26">
                        <ul className="account-list">
                            <li><Link to={'/profile'} className={params === '/profile' ? 'active' : ''}>{MultiLanguage("MENU_PROFILE")}</Link></li>
                            <li><Link to={'/home'} className={params === '/home' ? 'active' : ''}>{MultiLanguage('MENU_MAIN_WALLET')}</Link></li>
                            {/* <ul className="account-list">
                                <li><Link to={`/topup`} className={params === '/topup' ? 'active' : ''}>TU</Link></li>
                                <li><Link to={`/withdraw`} className={params === '/withdraw' ? 'active' : ''}>WD</Link></li>
                                <li><Link to={`/transfer`} className={params === '/transfer' ? 'active' : ''}>Transfer</Link></li>
                            </ul>
                            <li>Saved Wallet</li>
                            <ul className="account-list">
                                <li><Link to={`/savedwallet`} className={params === '/savedwallet' ? 'active' : ''}>Create Saved Wallet</Link></li>
                                <li><Link to={`/savedwalletlist`} className={params === '/savedwalletlist' ? 'active' : ''}>Saved Wallet List</Link></li>
                            </ul>
                            <li>Lingked Account</li>
                            <ul className="account-list">
                                <li><Link to={`/linkedaccount`} className={params === '/linkedaccount' ? 'active' : ''}>Create Lingked Account</Link></li>
                                <li><Link to={`/linkedaccountlist`} className={params === '/linkedaccountlist' ? 'active' : ''}>Lingked Account List</Link></li>
                            </ul> */}
                            <li><Link to="/logout">{MultiLanguage('MENU_LOGOUT')}</Link></li>
                        </ul>
                    </div>
                </div>
            </div> : '' }
        </>
    );
}

export default Account;