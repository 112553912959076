import { useEffect } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import cryptoJS from 'crypto-js';
import axios from "axios";

import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import { useState } from "react";
import { MultiLanguage } from "../../lang/Language";

const DetailToken = () => {
    const { id } = useParams();
    const [detailtoken, setDetailToken] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({
            type : 'BOX_ACCOUNT',
            newNavAccount : false
        });
    }, []);

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/token/v01/'+id+'/detail',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature,
            }
        }).then(res => {
            setDetailToken(res.data.data);
        }).catch(error => {
            console.log(error);
        });
    }, [id]);

    const insertNewTopupdwa = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/topupdwa/v01/insertnew',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                'tokenIndex' : id
            }
        }).then(res => {
            if(res.data.status.status === 1){
                dispatch({
                    type : 'TOKENTRANSDWA',
                    newtokentransdwa : res.data.status.tokentrans
                });

                navigate('/topupdwa');
            }else{
                console.log(res.data);
            }
        })
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <div className="main-wallet mt-4 mb-5">
                        <h3 className="text-primary">{MultiLanguage('TOKEN_DETAILS')}</h3>
                        
                        {Object.keys(detailtoken).length > 0 ?
                        <div className="mt-3 mb-5">
                            <div className="row">
                                <div className="col-sm-3">
                                    <img src={process.env.PUBLIC_URL+'/images/components/Image-2.png'} alt="Image" width="150" className="d-block m-auto mt-3"/>
                                </div>
                                <div className="col-sm tokendetail">
                                    <div className="token-list-detail mb-3">
                                        <div className="box-token-list">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="pt-2">
                                                        <h6 className="text-success">{MultiLanguage('TOKEN_NAME')}</h6>
                                                        <h5>{detailtoken.token_name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="token-list-detail mb-3">
                                        <div className="box-token-list">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="pt-2">
                                                        <h6 className="text-success">{MultiLanguage('TOKEN_ASSET')}</h6>
                                                        <h5>{detailtoken.token_asset}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="token-list-detail mb-3">
                                <div className="box-token-list">
                                    <div className="row">
                                        <div className="col">
                                            <div className="pt-2">
                                                <h6 className="text-success">{MultiLanguage('TOKEN_BALANCE_DWA')}</h6>
                                                <h5 className="text-success">{detailtoken.saldo_dwa} <span className="text-black">DWA</span></h5>
                                            </div>
                                        </div>
                                        <div className="col text-end">
                                            <div className="mt-3 float-end">
                                                <button className="btn btn-success d-block block" onClick={insertNewTopupdwa}>{MultiLanguage('BTN_TOPUP')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="token-list-detail mb-3">
                                <div className="box-token-list">
                                    <div className="row">
                                        <div className="col">
                                            <div className="pt-2">
                                                <h6 className="text-success">{MultiLanguage('TOKEN_ACCOUNT_RS')}</h6>
                                                <h5>{detailtoken.token_accountRS}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="token-list-detail mb-3">
                                <div className="box-token-list">
                                    <div className="row">
                                        <div className="col">
                                            <div className="pt-2">
                                                <h6 className="text-success">{MultiLanguage('TOKEN_ACCOUNT')}</h6>
                                                <h5>{detailtoken.token_account}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> : <img src={process.env.PUBLIC_URL+'/images/components/loadbars.gif'} width="100%" height="150px"/> }
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailToken;