import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import axios from "axios";
import cryptoJS from "crypto-js";

import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import ModalInformation from "../../modal/ModalInformation";
import ModalPin from "../../modal/ModalPin";

import { MultiLanguage } from '../../lang/Language';
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

const Topup = () => {
    const [tokenTrans, setTokenTrans] = useState('');
    const [modal, setModal] = useState(false);
    const [errorCode, setErrorCode] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');
    const [currencyCode, setCurrencyCode] = useState([]);
    const [selectCurrencyCode, setSelectCurrencyCode] = useState('');
    const [platformCode, setPlatformCode] = useState([]);
    const [selectPlatformCode, setSelectPlatformCode] = useState('');
    const [topup, setTopup] = useState({});
    const [information, setInfomation] = useState({});
    const [modalPin, setModalPin] = useState(false);

    const [stepOne, setStepOne] = useState(0);
    const [stepTwo, setStepTwo] = useState(0);
    const [stepThree, setStepThree] = useState(0);
    const [stepFour, setStepFour] = useState(0);
    const [stepFive, setStepFive] = useState(0);
    const [stepSix, setStepSix] = useState(0);

    const [formStep, setFormStep] = useState(0);

    const dispatch = useDispatch();
    const alert = useAlert();
    const navigate = useNavigate();

    const inputAmount = useRef();
    const inputMessage = useRef();
    const optionCurrency = useRef();
    const optionPlatform = useRef();

    useEffect(() => {
        dispatch({
            type : 'BOX_ACCOUNT',
            newNavAccount : false
        });
    }, []);

    const insertNew = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/insertnew',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setStepOne(1);
                setFormStep(1);
                setTokenTrans(res.data.status.tokentrans);
            }else{
                res.data.status.message.forEach(element => {
                    setModal(true);
                    setErrorCode('Warning!');
                    setDescriptionError(MultiLanguage(element.code));
                });   
            }
        })
    }

    const insertAmount = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;
        StringSignature += amount;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/storeamount',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                tokentrans : tokenTrans,
                tabletrxamount : inputAmount.current.value,
                tabletrxmessage : inputMessage.current.value
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setStepTwo(1)
                setFormStep(2);
                setCurrencyCode(res.data.support);
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code){
                        case '888999999950':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        case '888035020005':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        case '888999999925':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        default:
                            setModal(true);
                            setErrorCode('Warning!');
                            let replaceText = MultiLanguage(element.code).replace('###', element.codevariable);
                            setDescriptionError(replaceText);
                        break;
                    }
                });   
            }
        });
    }

    const insertCurrencyCode = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;
        StringSignature += selectCurrencyCode;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/storecurrency',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                tokentrans : tokenTrans,
                currencycode : optionCurrency.current.value
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setStepThree(1);
                setFormStep(3);
                setPlatformCode(res.data.support);
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code){
                        case '888999999950':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        case '888035020005':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        case '888999999925':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        default:
                            setModal(true);
                            setErrorCode('Warning!');
                            let replaceText = MultiLanguage(element.code).replace('###', element.codevariable);
                            setDescriptionError(replaceText);
                        break;
                    }
                });
            }
        })
    }

    const insertPlatformCode = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;
        StringSignature += selectPlatformCode;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/storepaymentmethod',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                tokentrans : tokenTrans,
                tablepaymentmethodindex : optionPlatform.current.value
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setStepFour(1);
                setFormStep(4);
                setTopup(res.data.data);
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code){
                        case '888999999950':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        case '888035020005':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        case '888999999925':
                            alert.error(MultiLanguage(element.code));
                            navigate('/home');
                        break;
                        default:
                            setModal(true);
                            setErrorCode('Warning!');
                            let replaceText = MultiLanguage(element.code).replace('###', element.codevariable);
                            setDescriptionError(replaceText);
                        break;
                    }
                });
            }
        })
    }

    // const storeHandle = (values) => {
    //     var resultPin = values.join();
    //     var StringPin = resultPin.replaceAll(',','');

    //     if(StringPin.length === 6){
    //         let useruuid = JSON.parse(localStorage.getItem('useruuid'));

    //         let StringSignature = '';

    //         StringSignature += 'WEBSITE';
    //         StringSignature += 'PASSS';
    //         StringSignature += useruuid.tableuserindex;
    //         StringSignature += useruuid.tokenlogin;
    //         StringSignature += tokenTrans;

    //         let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

    //         setModalPin(false);
    //         setModal(true);
    //         setErrorCode('Transaction process...');
    //         setDescriptionError('Please wait for your transaction to be processed!');

    //         axios({
    //             method : 'POST',
    //             url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/store',
    //             headers : {
    //                 platform : 'WEBSITE',
    //                 gtoken : 'PASSS',
    //                 userindex : useruuid.tableuserindex,
    //                 tokenlogin : useruuid.tokenlogin,
    //                 signature : signature
    //             },
    //             data : {
    //                 tokentrans : tokenTrans,
    //                 user2fapintoken : StringPin
    //             }
    //         }).then(res => {
    //             if(res.data.status.status === 1){
    //                 setModal(false);
    //                 setErrorCode('');
    //                 setDescriptionError('');

    //                 setStepFive(1);
    //                 setStepSix(1);
    //                 setFormStep(5);
    //                 setInfomation(res.data.data);
    //             }else{
    //                 res.data.status.message.forEach(element => {
    //                     setModal(true);
    //                     setErrorCode('Warning!');
    //                     setDescriptionError(MultiLanguage(element.code));
    //                 });

    //                 setModalPin(false);
    //             }
    //         })
    //     }
    // }

    const storeHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        // setModalPin(false);
        // setModal(true);
        // setErrorCode('Transaction process...');
        // setDescriptionError('Please wait for your transaction to be processed!');

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/store',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                tokentrans : tokenTrans
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setModal(false);
                setErrorCode('');
                setDescriptionError('');

                setStepFive(1);
                setStepSix(1);
                setFormStep(5);
                setInfomation(res.data.data);
            }else{
                res.data.status.message.forEach(element => {
                    setModal(true);
                    setErrorCode('Warning!');
                    setDescriptionError(MultiLanguage(element.code));
                });

                setModalPin(false);
            }

            console.log(res.data);
        })
    }

    const backHandle = (stepId) => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);


        if(stepId === '3'){
            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/back/storecurrency',
                headers : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    tokenlogin : useruuid.tokenlogin,
                    signature : signature
                },
                data : {
                    tokentrans : tokenTrans,
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    setStepTwo(0)
                    setFormStep(1);
                }
                else{
                    res.data.status.message.forEach(element => {
                        setModal(true);
                        setErrorCode('Warning!');
                        setDescriptionError(MultiLanguage(element.code));
                    });
                }
            })
        }

        if(stepId === '4'){
            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/back/storepaymentmethod',
                headers : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    tokenlogin : useruuid.tokenlogin,
                    signature : signature
                },
                data : {
                    tokentrans : tokenTrans,
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    setStepThree(0);
                    setFormStep(2);
                }
                else{
                    res.data.status.message.forEach(element => {
                        setModal(true);
                        setErrorCode('Warning!');
                        setDescriptionError(MultiLanguage(element.code));
                    });
                }
            })
        }

        if(stepId === '5'){
            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/deposit/v02/back/store',
                headers : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    tokenlogin : useruuid.tokenlogin,
                    signature : signature
                },
                data : {
                    tokentrans : tokenTrans,
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    setStepFour(0);
                    setFormStep(3);
                }
                else{
                    res.data.status.message.forEach(element => {
                        setModal(true);
                        setErrorCode('Warning!');
                        setDescriptionError(MultiLanguage(element.code));
                    });
                }
            })
        }
    }

    const InformationTopUp = () => {
        if(localStorage.getItem('LangApps') === 'ID'){
            return(
                <div className="alert alert-primary" role="alert">
                    HARAP DIPERHATIKAN:
                    <ul>
                        <li>Alamat yang tertera adalah khusus untuk Anda dan dapat digunakan berulang kali <b>(INI ADALAH ALAMAT ETHEREUM / ERC20).</b></li>
                        <li>Pastikan Anda mengirimkan USDT ke <b>ALAMAT DAN JARINGAN YANG BENAR (CEK KEMBALI JARINGAN YANG AKAN DIGUNAKAN).</b></li>
                        <li>Jangan mengirimkan Koin/Token dengan <b>JARINGAN YANG BERBEDA DARI KETERANGAN JARINGAN YANG TERTERA.</b></li>
                        <li>Mengirim Koin/Token selain USDT ke alamat tertera akan menyebabkan <b>KOIN/TOKEN TERSEBUT HILANG.</b></li>
                        <li>Deposit akan masuk setelah 12 konfirmasi.</li>
                        <li>Deposit tidak dikenakan biaya.</li>
                        <li><b>BIAYA TAMBAHAN AKAN DIKENAKAN</b> untuk proses pengembalian dana pada Koin/Token yang dikirim ke alamat yang salah.</li>
                    </ul>
                </div>
            );
        }else if(localStorage.getItem('LangApps') === 'EN'){
            return(
                <div className="alert alert-primary" role="alert">
                    PLEASE NOTE:
                    <ul>
                        <li>The address listed is unique to you and can be used repeatedly <b>(THIS IS AN ETHEREUM/ERC20 ADDRESS).</b></li>
					<li>Make sure you are sending USDT <b> to the CORRECT ADDRESS AND NETWORK (REVIEW THE NETWORK THAT WILL BE USED).</b></li>
                        <li>Do not send Coins/Tokens with a <b>DIFFERENT NETWORK THAN THE NETWORK DESCRIPTION STATED.</b></li>
                        <li>Sending Coins/Tokens other than USDT to the address stated will cause <b>THE COINS/TOKENS TO BE LOST.</b></li>
                        <li>Deposit will come after 12 confirmations.</li>
                        <li>Deposit is free of charge.</li>
                        <li><b>ADDITIONAL FEES WILL BE CHARGED</b> for refund processing on Coins/Tokens sent to the wrong address.</li>
                    </ul>
                </div>
            );
        }else{
            return(
                <div className="alert alert-primary" role="alert">
                    PLEASE NOTE:
                    <ul>
                        <li>The address listed is unique to you and can be used repeatedly <b>(THIS IS AN ETHEREUM/ERC20 ADDRESS).</b></li>
					<li>Make sure you are sending USDT <b> to the CORRECT ADDRESS AND NETWORK (REVIEW THE NETWORK THAT WILL BE USED).</b></li>
                        <li>Do not send Coins/Tokens with a <b>DIFFERENT NETWORK THAN THE NETWORK DESCRIPTION STATED.</b></li>
                        <li>Sending Coins/Tokens other than USDT to the address stated will cause <b>THE COINS/TOKENS TO BE LOST.</b></li>
                        <li>Deposit will come after 12 confirmations.</li>
                        <li>Deposit is free of charge.</li>
                        <li><b>ADDITIONAL FEES WILL BE CHARGED</b> for refund processing on Coins/Tokens sent to the wrong address.</li>
                    </ul>
                </div>
            );
        }
    }

    const SuccessInformastion = () => {
        if(localStorage.getItem('LangApps') === 'EN'){
            return(
                <>
                    <p className="font-size-40 text-center">Success</p>
                    <p className="text-center">Your transaction <b>{information.pay_code}</b> status now is queueing</p>
                    <p className="text-center font-weight-700 pb-4">We will email you our DEX wallet ID. Please check your email inbox.</p>
                </>
            )
        }else if(localStorage.getItem('LangApps') === 'ID'){
            return(
                <>
                    <p className="font-size-40 text-center">Berhasil</p>
                    <p className="text-center">Transaksi Anda<b>{information.pay_code}</b> status sekarang sedang mengantri</p>
                    <p className="text-center font-weight-700 pb-4">Kami akan mengirim email kepada Anda ID dompet DEX kami. Silakan periksa kotak masuk email Anda.</p>
                </>
            )
        }else{
            return(
                <>
                    <p className="font-size-40 text-center">Success</p>
                    <p className="text-center">Your transaction <b>{information.pay_code}</b> status now is queueing</p>
                    <p className="text-center font-weight-700 pb-4">We will email you our DEX wallet ID. Please check your email inbox.</p>
                </>
            )
        }
        
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <h3 className="text-center text-primary">{MultiLanguage('TITLE_TOPUP')}</h3>
                    <div className="progressbar desktopProgress">
                        <div className="stepper-wrapper">
                            <div className={stepOne === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name">
                                    <img src={process.env.PUBLIC_URL + '/images/components/Pen.png'} alt="Pen.png"/>
                                    {MultiLanguage('TITLE_INFO')}
                                </div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepTwo === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name">
                                    <img src={process.env.PUBLIC_URL + '/images/components/Amount.png'} alt="Amount.png"/>
                                    {MultiLanguage('TITLE_AMOUNT')}
                                </div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepThree === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name">
                                    <img src={process.env.PUBLIC_URL + '/images/components/Currency.png'} alt="Currency.png"/>
                                    {MultiLanguage('TITLE_CURRENCY')}
                                </div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepFour === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name">
                                    <img src={process.env.PUBLIC_URL + '/images/components/Platform-Crypto.png'} alt="Platform-Crypto.png"/>
                                    {MultiLanguage('TITLE_PLATFORM')}
                                </div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepFive === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name">
                                    <img src={process.env.PUBLIC_URL + '/images/components/shield-check.png'} alt="shield-check.png"/>
                                    {MultiLanguage('TITLE_CONFIRMATION')}
                                </div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepSix === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name">
                                    <img src={process.env.PUBLIC_URL + '/images/components/Approve.png'} alt="Approve.png"/>
                                    {MultiLanguage('TITLE_DONE')}
                                </div>
                                <div className="step-counter"></div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3 imgProgress">
                        {formStep === 0 ? <>
                        <img src={process.env.PUBLIC_URL + '/images/components/Pen.png'} alt="Pen.png" className="d-block m-auto"/>
                        <h3 className="text-center pt-2">{MultiLanguage('TITLE_INFO')}</h3></> : '' }

                        {formStep === 1 ? <>
                        <img src={process.env.PUBLIC_URL + '/images/components/Amount.png'} alt="Amount.png" className="d-block m-auto"/>
                        <h3 className="text-center pt-2">{MultiLanguage('TITLE_AMOUNT')}</h3></> : '' }

                        {formStep === 2 ? <>
                        <img src={process.env.PUBLIC_URL + '/images/components/Currency.png'} alt="Currency.png" className="d-block m-auto"/>
                        <h3 className="text-center pt-2">{MultiLanguage('TITLE_CURRENCY')}</h3></> : '' }

                        {formStep === 3 ? <>
                        <img src={process.env.PUBLIC_URL + '/images/components/Platform-Crypto.png'} alt="Platform-Crypto.png" className="d-block m-auto"/>
                        <h3 className="text-center pt-2">{MultiLanguage('TITLE_PLATFORM')}</h3></> : '' }

                        {formStep === 4 ? <>
                        <img src={process.env.PUBLIC_URL + '/images/components/shield-check.png'} alt="shield-check.png" className="d-block m-auto"/>
                        <h3 className="text-center pt-2">{MultiLanguage('TITLE_CONFIRMATION')}</h3></> : '' }

                        {formStep === 5 ? <>
                        <img src={process.env.PUBLIC_URL + '/images/components/Approve.png'} alt="Approve.png" className="d-block m-auto"/>
                        <h3 className="text-center pt-2">{MultiLanguage('TITLE_DONE')}</h3></> : '' }
                    </div>

                    <div className="progressbar mobileProgress">
                        <div className="stepper-wrapper">
                            <div className={stepOne === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name"></div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepTwo === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name"></div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepThree === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name"></div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepFour === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name"></div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepFive === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name"></div>
                                <div className="step-counter"></div>
                            </div>
                            <div className={stepSix === 1 ? 'stepper-item completed' : 'stepper-item'}>
                                <div className="step-name"></div>
                                <div className="step-counter"></div>
                            </div>
                        </div>
                    </div>
                    
                    {formStep === 0 ?
                    <div className="box-transaction">
                        {InformationTopUp()}
                        <div className="mb-3 mt-5">
                            <button className="btn btn-primary d-block m-auto" onClick={insertNew}>{MultiLanguage('BTN_CREATE')}</button>
                        </div>
                    </div> : '' }
                    
                    {formStep === 1 ?
                    <div className="box-transaction">
                        <div className="mb-3">
                            <label htmlFor="tabletopupamount" className="form-label">{MultiLanguage('tabletopupamount')}</label>
                            <input type="text" name="tabletopupamount" className="form-control" ref={inputAmount}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="tabletrxmessage" className="form-label">{MultiLanguage('tabletrxmessage')}</label>
                            <textarea name="tabletrxmessage" className="form-control" ref={inputMessage}/>
                        </div>
                        <center>
                            <div className="btnProses">  
                                <button type="button" className="btn btn-primary d-block m-auto" onClick={insertAmount}>{MultiLanguage('BTN_NEXT')}</button>
                            </div>
                        </center>
                    </div> : ''}

                    {formStep === 2 ?    
                    <div className="box-transaction">
                        <div className="mb-5">
                            <select name="tabletopupcurrencycode" className="form-control" ref={optionCurrency}>
                                <option value="">{MultiLanguage('OPSI_CURRENCY')}</option>
                                {currencyCode.map((item, i) => (
                                    <option key={i} value={item.temptrxcurrencyratecurrencycode}>{item.temptrxcurrencyratecurrencycode + ' - ' + item.temptrxcurrencyrateraterate}</option>
                                ))}
                            </select>
                        </div>
                        <center>
                            <div className="btnProses">
                                <button type="button" className="btn btn-outline-primary d-block m-auto" onClick={() => backHandle('3')}>{MultiLanguage('BTN_BACK')}</button>    
                                <button type="button" className="btn btn-primary d-block m-auto" onClick={insertCurrencyCode}>{MultiLanguage('BTN_NEXT')}</button>
                            </div>
                        </center>
                    </div> : '' }

                    {formStep === 3 ?
                    <div className="box-transaction">
                        <div className="mb-5">
                            <select name="tabletopupcurrencyplatformcode" className="form-control" ref={optionPlatform}>
                                <option value="">{MultiLanguage('OPSI_PLATFORM')}</option>
                                {platformCode.map((item, i) => (
                                    <option key={i} value={item.index}>{item.code+' | '+item.description}</option>
                                ))}
                            </select>
                        </div>
                        <center>
                            <div className="btnProses">
                                <button type="button" className="btn btn-outline-primary d-block m-auto" onClick={() => backHandle('4')}>{MultiLanguage('BTN_BACK')}</button>    
                                <button type="button" className="btn btn-primary d-block m-auto" onClick={insertPlatformCode}>{MultiLanguage('BTN_NEXT')}</button>
                            </div>
                        </center>
                    </div> : '' }

                    {formStep === 4 ?
                    <div className="box-transaction">
                        <h3 className="text-center text-primary">{MultiLanguage('TILE_CONFIRMATION')}</h3>
                        <div className="card mb-4 mt-4">
                            <div className="card-body p-5">
                                <div className="row">
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('CONFIRMATION_AMOUNT')}</label>
                                        <p>{topup.tempdepositamount}</p>
                                    </div>
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('CONFIRMATION_CURRENCY')}</label>
                                        <p>{topup.tempdepositcurrencycode}</p>
                                    </div>
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('CONFIRMATION_PLATFORM')}</label>
                                        <p>{topup.tempdepositpaymentmethoddescription}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <center>
                            <div className="btnProses">
                                <button type="button" className="btn btn-outline-primary d-block m-auto" onClick={() => backHandle('5')}>{MultiLanguage('BTN_BACK')}</button>    
                                <button type="button" className="btn btn-primary d-block m-auto" onClick={storeHandle}>{MultiLanguage('BTN_NEXT')}</button>
                            </div>
                        </center>
                    </div> : '' }

                    {formStep === 5 ?
                    <div className="box-transaction">
                        <div className="alert alert-success" role="alert">
                            <img src={process.env.PUBLIC_URL+'/images/components/check-circle-fill.png'} alt="check-circle-fill.png" className="d-block m-auto mt-4"></img>
                            {SuccessInformastion()}
                        </div>
                    </div> : '' }

                    <ModalInformation 
                    show={modal} 
                    onHide={() => setModal(false)}
                    errorCode={errorCode}
                    description={descriptionError}
                    />

                    <ModalPin
                    show={modalPin} 
                    onHide={() => setModalPin(false)}
                    executePin={storeHandle}
                    />
                </div>
            </div>
        </>
    );
}

export default Topup;