import { useSelector } from 'react-redux';
import Notification from './subnavcontent/Notification';
import Account from './subnavcontent/Account';
import Translation from './subnavcontent/Translation';

const NavContent = () => {
    const navNotification = useSelector(state => state.navNotification);
    const navAccount = useSelector(state => state.navAccount);
    const navTranslation = useSelector(state => state.navTranslation);

    return(
        <>
            { navNotification ? <>
            <div className="d-block">
                <Notification/>
            </div>

            <div className="opacity-0">
                <Account/>
            </div>

            <div className="opacity-0">
                <Translation/>
            </div>
            </> :
            <>
            <div className="d-none">
                <Notification/>
            </div>

            <div className="d-none">
                <Account/>
            </div>

            <div className="d-none">
                <Translation/>
            </div>
            </> }


            {navAccount ? 
            <>
            <div className="opacity-0">
                <Notification/>
            </div>

            <div className="d-block">
                <Account/>
            </div>

            <div className="opacity-0">
                <Translation/>
            </div>
            </> : <>
            <div className="d-none">
                <Notification/>
            </div>

            <div className="d-none">
                <Account/>
            </div>

            <div className="d-none">
                <Translation/>
            </div>
            </>}


            {navTranslation ? 
            <>
            <div className="opacity-0">
                <Notification/>
            </div>

            <div className="opacity-0">
                <Account/>
            </div>

            <div className="d-block">
                <Translation/>
            </div>
            </> : <>
            <div className="d-none">
                <Notification/>
            </div>

            <div className="d-none">
                <Account/>
            </div>

            <div className="d-none">
                <Translation/>
            </div>
            </>}
        </>
    );
}

export default NavContent;