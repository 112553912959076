import { useState } from "react";
import { Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import Navbar from "../layout/Navbar";
import axios from "axios";

import { MultiLanguage } from "../lang/Language";

const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [isRedirect, setIsRedirect] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const [errorUsername, setErrorUsername] = useState('');
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
        // setBtnLoading(true);

        let StringSignature = '';
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += username;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/auth/v01/forgotpassword',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                signature : signature
            },
            data : {
                tableusername : username,
                tableuserlanguage : 'EN'
            }
        }).then(res => {
            if(res.data.status.status === 1){
                dispatch({
                    type : 'PAGE_SUCCESS',
                    newPageSuccess : 'emailSuccess'
                });
                setIsRedirect(true);
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code.substring(0,9)){
                        case '888005015':
                            setErrorUsername(element.code);
                        break;
                    }
                })
            }
            setBtnLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }

    if(isRedirect){
        return <Navigate to={`/success`}/>
    }
    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="container mt-100">
                    <div className="card w-569 m-auto p-3 shadow">
                        <div className="card-body">
                            {/* <h3>Forgot Password</h3> */}
                            <h3>{MultiLanguage('FORGOT_PASSWORD')}</h3>
                            {/* <span>Enter your username associated with your account and we'll send an email with instructions to reset your password</span> */}
                            <span>{MultiLanguage('DESCRIPTION_FORGOT_PASSWORD_PAGE')}</span>
                            <div className="mt-4 loginForm">
                                <form method="POST" onSubmit={onSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="tableusername" className="form-label">{MultiLanguage('tableusername')}</label>
                                        <input type="text" name="tableusername" className={errorUsername.substring(0,9) === '888005015' ? 'form-control border border-danger' : 'form-control'} onChange={(e) => setUsername(e.target.value)}/>
                                        {errorUsername.substring(0,9) === '888005015' ? <span className="text-danger errorMessage">{MultiLanguage(errorUsername)}</span> : ''}
                                    </div>
                                    <div className="mb-3">
                                        {btnLoading ? 
                                            <button className="btn btn-primary btw-150 m-auto d-block" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                            </button> :
                                        <button type="submit" className="btn btn-primary d-block m-auto">{MultiLanguage('BTN_RESET_PASSWORD')}</button> }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;