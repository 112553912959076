import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from "react-alert";

import cryptoJS from "crypto-js";
import axios from "axios";

import Navbar from "../layout/Navbar";
import { useDispatch, useSelector } from "react-redux";

import { MultiLanguage } from "../lang/Language";

const Login = () => {
    const [Tableusername, setTableusername] = useState(null);
    const [Tablepassword, setTablepassword] = useState(null);
    // const [isCodeError, setCodeError] = useState(null);
    // const [errorMessage, setErrorMessage] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);
    const [eye, setEye] = useState(false);

    const notif = useSelector(state => state.notif);
    const latitude = useSelector(state => state.latitude);
    const longitude = useSelector(state => state.longitude);

    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    console.log(latitude);

    const onSubmit = (e) => {
        e.preventDefault();
        // setBtnLoading(true);

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += Tableusername;
        StringSignature += Tablepassword;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/auth/v01/signin',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                signature : signature
            },
            data : {
                tableusername : Tableusername,
                tableuserpassword : Tablepassword,
                latitude : latitude,
                longitude : longitude,
                tableuserlanguage : 'EN'
            }
        }).then(res => {
            if(res.data.status.status === 0){
                // if(res.data.status.code === '888065165005'){
                //     alert.error(res.data.status.code);
                // }else if(res.data.status.code === '888065220005'){
                //     alert.error(res.data.status.code);
                // }else{
                //     setCodeError(res.data.status.code.substring(0,9));
                //     setErrorMessage(res.data.status.code);
                // }

                res.data.status.message.forEach(element => {
                    switch(element.code.substring(0,9)){
                        case '888005015':
                            setErrorUsername(element.code);
                        break;
                        case '888005115':
                            setErrorPassword(element.code);
                        break;
                        default:
                            alert.error(MultiLanguage(element.code))
                        break;
                    }
                });

                let inputUsername = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005015');
                let inputPassword = res.data.status.message.filter(key => key.code.substring(0,9) ==='888005115');

                if(inputUsername.length === 0){setErrorUsername('');}
                if(inputPassword.length === 0){setErrorPassword('');}
            }else{
                localStorage.setItem('useruuid', JSON.stringify(res.data.data));
                // setCodeError(null);
                navigate('/home');
            }
            // setBtnLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }

    const eyeHandle = () => {
        if(eye){
            setEye(false);
        }else{
            setEye(true);
        }
    }

    useEffect(() => {
        if(notif !== ''){
            alert.show(notif);

            dispatch({
                type : 'notif',
                newNotif : ''
            })
        }
    }, [notif, dispatch, alert]);

    const ValidationError = () => {
        let validationError;

        if(errorPassword.substring(0,9) === '888005115'){
            validationError = 'form-control border border-danger';
        }else{
            validationError = 'form-control border-right-none';
        }
        // else if(isCodeError === '888065010'){
        //     validationError = 'form-control border border-danger';
        // }else if(isCodeError === '888065090'){
        //     validationError = 'form-control border border-danger';
        // }else{
        //     validationError = 'form-control border-right-none';
        // }


        return validationError;
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="container mt-100">
                    <div className="card w-569 m-auto p-3 shadow">
                        <div className="card-body">
                            {/* <h3>Login</h3> */}
                            <h3>{MultiLanguage('FORM_LOGIN')}</h3>
                            <div className="mt-4 loginForm">
                                <form method="POST" onSubmit={onSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="tableusername" className="form-label">{MultiLanguage('tableusername')}</label>
                                        <input type="text" name="tableusername" className={errorUsername.substring(0,9) === '888005015' ? 'form-control border border-danger' : 'form-control'} onChange={(e) => setTableusername(e.target.value)}/>
                                        {errorUsername.substring(0,9) === '888005015' ? <span className="text-danger d-block errorMessage">{MultiLanguage(errorUsername)}</span> : null}
                                    </div>
                                    <label htmlFor="tableuserpassword" className="form-label">{MultiLanguage('tableuserpassword')}</label>
                                    <div className="input-group">
                                        <input type={eye ? 'text' : 'password'} className={ValidationError()} autoComplete="on" onChange={(e) => setTablepassword(e.target.value)}/>
                                        <span className="input-group-text bg-none pointer" onClick={eyeHandle}>
                                            {eye ? 
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye.png'} alt="eye.png"></img>:
                                            <img src={process.env.PUBLIC_URL+'/images/components/eye-slash.png'} alt="eye-slash.png"></img> }
                                        </span>
                                    </div>
                                    {errorPassword.substring(0,9) === '888005115' ? <span className="text-danger d-block errorMessage">{MultiLanguage(errorPassword)}</span> : null}
                                    {/* {isCodeError === '888065010' ? <span className="text-danger d-block">{errorMessage}</span> : null} */}
                                    {/* {isCodeError === '888065090' ? <span className="text-danger d-block">{errorMessage}</span> : null} */}
                                    <div className="row pt-2">
                                        <div className="col">
                                            <Link to={`/forgot-password`} className="text-start d-block">{MultiLanguage('FORGOT_PASSWORD')}?</Link>
                                        </div>
                                        {/* <div className="col">
                                            <Link to={`/forgot-username`} className="text-end d-block">Forgot Username?</Link>
                                        </div> */}
                                    </div>
                                    <div className="mb-3 mt-4">
                                        {btnLoading ? 
                                            <button className="btn btn-primary btw-150 m-auto d-block" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                            </button> :
                                            <button className="btn btn-primary btw-150 m-auto d-block" type="submit">{MultiLanguage('BTN_LOGIN')}</button>
                                        }
                                    </div>
                                </form>
                            </div>
                            {/* <div className="text-center">
                                <span>Don't have an account <Link to={`/new-register`} className="text-decoration-none">Register Now</Link></span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;