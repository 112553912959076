import { useState } from "react";
import { useSelector } from "react-redux";
import cryptoJS from 'crypto-js';
import axios from "axios";

import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { MultiLanguage } from "../../lang/Language";
import { useRef } from "react";

const TopupDwa = () => {
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState('');
    const navigate = useNavigate();
    const alert = useAlert();
    const tokentransdwa = useSelector(state => state.tokentransdwa);
    const [topupinfo, setTopupInfo] = useState({});

    const inputAmount = useRef();

    const amountHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/topupdwa/v01/storeamount',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                'tokentrans' : tokentransdwa,
                'amount' : inputAmount.current.value,
                'message' : 'Topup DWA'
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setTopupInfo(res.data.data);
                setStep(1);
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code.substring(0, 9)){
                        case '888035020':
                            navigate('/home');
                            alert.error(MultiLanguage(element.code));
                        break;
                        case '888050040':
                            alert.error(MultiLanguage(element.code));
                        break;
                        default:
                            alert.error(MultiLanguage(element.code));
                        break;
                    }
                })
            }
        })
    }

    const storeHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/topupdwa/v01/store',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                'tokentrans' : tokentransdwa
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setStep(2);
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code.substring(0, 9)){
                        case '888035020':
                            navigate('/home');
                        break;
                    }
                })
            }
        })
    }

    const successInfo = () => {
        if(localStorage.getItem('LangApps') === 'EN'){
            return(
                <>
                    <p className="font-size-40 text-center">Success</p>
                    <p className="text-center">Check your email, for confirmation</p>
                </>
            )
        }else if(localStorage.getItem('LangApps') === 'ID'){
            return(
                <>
                    <p className="font-size-40 text-center">Berhasil</p>
                    <p className="text-center">Cek email Anda, untuk konfirmasi</p>
                </>
            )
        }else{
            return(
                <>
                    <p className="font-size-40 text-center">Success</p>
                    <p className="text-center">Check your email, for confirmation</p>
                </>
            )
        }
        
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    { step === 0 ? <>
                    <h3 className="text-center text-primary">{MultiLanguage('TOPUP_DWA')}</h3>
                    <div className="profile mt-5">
                        <label htmlFor="amount" className="form-label">{MultiLanguage('tableamount')}</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" ref={inputAmount}/>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">DWA</span>
                            </div>
                        </div>
                        <div className="mb-3 mt-5">
                            <button className="btn btn-primary d-block m-auto" onClick={amountHandle}>{MultiLanguage('BTN_VERIFICATION')}</button>
                        </div>
                    </div> </> : '' }

                    { step === 1 ? <>
                    <div className="box-transaction">
                        <h3 className="text-center text-primary">{MultiLanguage('CONFIRMATION_TOPUPDWA')}</h3>
                        <div className="card mb-4 mt-4">
                            <div className="card-body p-5">
                                <div className="row">
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('TOPUP_DWA_ACCOUNT')}</label>
                                        <p>{ topupinfo.tempdepositaccount }</p>
                                    </div>
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('TOPUP_DWA_AMOUNT')}</label>
                                        <p>{ topupinfo.tempdepositamount }</p>
                                    </div>
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('TOPUP_DWA_DESCRIPTION')}</label>
                                        <p>{ topupinfo.tempdepositdescription }</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('TOPUP_DWA_TOKENAKUN')}</label>
                                        <p>{ topupinfo.token_account }</p>
                                    </div>
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('TOPUP_DWA_ACCOUNTRS')}</label>
                                        <p>{ topupinfo.token_accountRS }</p>
                                    </div>
                                    <div className="col">
                                        <label className="font-weight-700">{MultiLanguage('TOPUP_DWA_TOKENASSET')}</label>
                                        <p>{ topupinfo.token_asset }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <center>
                            <div className="btnProses">   
                                <button type="button" className="btn btn-primary d-block m-auto" onClick={storeHandle}>{MultiLanguage('BTN_NEXT')}</button>
                            </div>
                        </center>
                    </div> </> : '' }

                    { step === 2 ? <>
                    <div className="box-transaction">
                        <div className="alert alert-success" role="alert">
                            <img src={process.env.PUBLIC_URL+'/images/components/check-circle-fill.png'} alt="check-circle-fill.png" className="d-block m-auto mt-4"></img>
                            {successInfo()}
                        </div>
                    </div>
                    </> : '' } 
                </div>
            </div>
        </>
    );
}

export default TopupDwa;