import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import cryptoJS from "crypto-js";
import { useState } from "react";
import { MultiLanguage } from "../../../lang/Language";

const Notification = () => {
    const [activity, setActivify] = useState([]);

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        if(useruuid !== null){
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'GET',
                url : process.env.REACT_APP_APIHOST+'/api/activity/v01/lists',
                headers : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    tokenlogin : useruuid.tokenlogin,
                    signature : signature
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    setActivify(res.data.data);
                }
            });
        }
    }, []);
    return(
        <>
            { localStorage.getItem('useruuid') !== null ? 
            <div className="card notification">
                <div className="card-body">
                    <ul className="activity">
                        {activity.map((result, key) => (
                            <li key={key}>{result.action} - {new Date(result.datetime).toLocaleDateString()}</li>
                        ))}
                    </ul>
                </div>
                <div className="card-footer">
                    <Link to={`/notification`}>{MultiLanguage('NOTIFICATION_LIST')}</Link>
                </div>
            </div> : '' }
        </>
    );
}

export default Notification;