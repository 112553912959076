import { useCallback, useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { MultiLanguage } from "../../lang/Language";

import axios from "axios";
import cryptoJS from "crypto-js";
import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import ModalPassword from "../../modal/ModalPassword";
import ModalOTP from "../../modal/ModalOTP";

const Profile = () => {

    const [profile, setProfile] = useState({});
    const [copyied, setCopyied] = useState(false);
    const copyClipboardValue = useRef('');
    const [isRedirect, setRedirect] = useState(false);

    const [tableuserfullname, setTableuserfullname] = useState('');
    const [tableusername, setTableusername] = useState('');
    const [userPinNew, setUserPinNew] = useState('');
    const [supportPhoneCode, setSupportPhoneCode] = useState([]);
    const [tableuserphonecountrycode, settableuserphonecountrycode] = useState('');
    const [tableuserphonenumbershort, settableuserphonenumbershort] = useState('');

    const modalPassword = useSelector(state => state.modalPassword);
    const ResetFormProfile = useSelector(state => state.ResetFormProfile);
    const [errorOtp, setErroOtp] = useState('');

    const [modalOtp, setModalOtp] = useState(false);

    const dispatch = useDispatch();
    const alert = useAlert();

    const profileLoad = useCallback(() => {
        let StringSignature = '';
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/v01/profile',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setProfile(res.data.data);
                setTableuserfullname(res.data.data.tableuserfullname);
                setTableusername(res.data.data.tableusername);
                settableuserphonecountrycode(res.data.data.tableuserphonecountrycode);
                settableuserphonenumbershort(res.data.data.tableuserphonenumbershort);
            }
        })
    }, []);

    const phonecodeLoad = useCallback(() => {
        let StringSignature = '';
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/v01/insertchangephonenumber',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setSupportPhoneCode(res.data.support);
            }
        })
    }, []);

    useEffect(() => {
        profileLoad();
        phonecodeLoad();
    }, [profileLoad]);

    useEffect(() => {
        dispatch({
            type : 'BOX_ACCOUNT',
            newNavAccount : false
        });
    }, []);

    const saveHandleConfirmation = (userPassword) => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        if(ResetFormProfile === 'full_name'){
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += userPassword;
            StringSignature += tableuserfullname;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/V01/changeuserfullname',
                headers : {
                    signature : signature
                },
                data : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    username : useruuid.tableusername,
                    tokenlogin : useruuid.tokenlogin,
                    userpassword : userPassword,
                    tableuserfullname : tableuserfullname
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    localStorage.removeItem('useruuid');
                    dispatch({
                        type : 'NOTIF',
                        newNotif : 'Please Re-login!'
                    });
                    setRedirect(true);
                }
                else{
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });

                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    alert.show(res.data.status.code);
                    profileLoad();                    
                }
            })
        }

        if(ResetFormProfile === 'username'){
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += tableusername;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/v01/changeusername',
                headers : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    tokenlogin : useruuid.tokenlogin,
                    signature : signature
                },
                data : {
                    tableusername : tableusername
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    localStorage.removeItem('useruuid');
                    dispatch({
                        type : 'NOTIF',
                        newNotif : 'Please Re-login!'
                    });
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });
                    setRedirect(true);
                }else{
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });
                    
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    alert.show(res.data.status.code);
                    profileLoad();
                }
            })
        }

        if(ResetFormProfile === 'resetpin')
        {
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += tableusername;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/v01/resetpin',
                headers : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    tokenlogin : useruuid.tokenlogin,
                    signature : signature
                },
                data : {
                    userpassword : userPassword,
                    userpinnew : userPinNew
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    dispatch({
                        type : 'NOTIF',
                        newNotif : 'Please Re-login!'
                    });
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });
                    alert.show('Create pin successfuly.');
                    setUserPinNew('');
                }else{
                    dispatch({
                        type : 'MODAL_PASSWORD',
                        newModalPassword : false
                    });
                    
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    res.data.status.message.forEach(element => {
                        switch(element.code){
                            case '888005200005':
                                alert.error(element.errormassage)
                            break;
                            case '888005115005':
                                alert.error(element.errormassage)
                            break;
                        }
                    });

                    setUserPinNew('');
                    profileLoad();
                }
            })
        }

        if(ResetFormProfile === 'phonenumber'){
            let StringSignature = '';

            StringSignature += 'WEBSITE';
            StringSignature += 'PASSS';
            StringSignature += useruuid.tableuserindex;
            StringSignature += useruuid.tokenlogin;
            StringSignature += tableusername;

            let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

            axios({
                method : 'POST',
                url : process.env.REACT_APP_APIHOST+'/api/user/v01/changephonenumber',
                headers : {
                    platform : 'WEBSITE',
                    gtoken : 'PASSS',
                    userindex : useruuid.tableuserindex,
                    tokenlogin : useruuid.tokenlogin,
                    signature : signature
                },
                data : {
                    userphonecountrycode : tableuserphonecountrycode,
                    userphonenumbershort : tableuserphonenumbershort
                }
            }).then(res => {
                if(res.data.status.status === 1){
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    alert.show('Change phone number successfuly');
                }else{
                    dispatch({
                        type : 'RESET_FORM_PROFILE',
                        newResetFormProfile : ''
                    });

                    res.data.status.message.forEach(element => {
                        switch(element.code){
                            case '888005200005':
                                alert.error(element.errormassage)
                            break;
                            case '888005115005':
                                alert.error(element.errormassage)
                            break;
                        }
                    });
                }
                profileLoad();
            });
        }
    }

    const copyClipboard = () => {
        navigator.clipboard.writeText(copyClipboardValue.current.value);
        setCopyied(true);
    }

    // const fullNameEdit = () => {
    //     dispatch({
    //         type : 'RESET_FORM_PROFILE',
    //         newResetFormProfile : 'full_name'
    //     });
    // }

    const usernameEdit = () => {
        dispatch({
            type : 'RESET_FORM_PROFILE',
            newResetFormProfile : 'username'
        });
    }

    const phonenumberedit = () => {
        dispatch({
            type : 'RESET_FORM_PROFILE',
            newResetFormProfile : 'phonenumber'
        });
    }

    const createpin = () => {
        dispatch({
            type : 'RESET_FORM_PROFILE',
            newResetFormProfile : 'resetpin'
        });
    }

    const saveHandle = () => {
        if(tableuserfullname !== ''){
            dispatch({
                type : 'MODAL_PASSWORD',
                newModalPassword : true
            });
        }

        if(tableusername !== ''){
            dispatch({
                type : 'MODAL_PASSWORD',
                newModalPassword : true
            });
        }

        if(userPinNew !== ''){
            dispatch({
                type : 'MODAL_PASSWORD',
                newModalPassword : true
            });
        }
    }

    const requestOtpHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tableusername;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/v01/requestotp',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                userphonecountrycode : tableuserphonecountrycode,
                userphonenumbershort : tableuserphonenumbershort
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setModalOtp(true);
            }else{
                res.data.status.message.forEach(element => {
                    switch(element.code){
                        case '888999999945':
                            alert.error(element.errormassage)
                        break;
                    }
                });
            }
        });
    }

    const validateOtpHandle = (values) => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tableusername;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/user/v01/validateotp',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            },
            data : {
                otp : values,
            }
        }).then(res => {
            if(res.data.status.status === 1){
                profileLoad();
                setModalOtp(false);
            }else{
                res.data.status.message.forEach(element => {
                    let replaceError = element.errormassage.replace("###", element.codevariable);

                    switch(element.code.substring(0, 9)){
                        case '888005095':
                            setErroOtp(replaceError)
                        case '888005075':
                            setErroOtp(replaceError)
                        break;
                    }
                });

                setTimeout(() => {
                    setErroOtp('');
                }, 3000);
            }
        });
    }

    setTimeout(() => {
        setCopyied(false);
    }, 5000);

    if(isRedirect){
        return <Navigate to={'/login'}/>
    }

    let resultArrPhoneCode = supportPhoneCode.map((result, key) => {
        return(
            <option key={key} value={result.tablecountryphonecodephonecode}>+{result.tablecountryphonecodephonecode}</option>
        )
    })

    const FooterProfile = () => {
        if(localStorage.getItem('LangApps') === 'EN'){
            return(
                <>
                    <span className="d-block text-center">Last password change 30-05-2022 18:23</span>
                    <span className="d-block text-center">Click <Link to="#">Forgot Password</Link> to update your password</span>
                </>
            )
        }else if(localStorage.getItem('LangApps') === 'ID'){
            return(
                <>
                    <span className="d-block text-center">Perubahan kata sandi terakhir 05-30-2022 18:23</span>
                    <span className="d-block text-center">Klik <Link to="#">Lupa Kata sandi</Link> untuk ubah password kamu</span>
                </>
            )
        }
    }

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <h3 className="text-center text-primary">{MultiLanguage('TITLE_PROFILE')}</h3>
                    <div className="profile mt-5">
                        { Object.keys(profile).length > 0 ? <>
                        <div className="row mb-3">
                            <label htmlFor="tableuserreferralcode" className="form-label">{MultiLanguage('tableuserreferralcode')}</label>
                            <div className="col-sm">
                                <input type="text" name="tableuserreferralcode" ref={copyClipboardValue} className="form-control" value={profile.tableuserreferralcode || ''} disabled/>
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-primary form-control" disabled={copyied ? 'disabled' : ''} onClick={copyClipboard}>{copyied ? MultiLanguage('BTN_COPYIED') : MultiLanguage('BTN_COPY')}</button>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableuseremail" className="form-label">{MultiLanguage('tableuseremail')}</label>
                            <div className="col">
                                <input type="text" name="tableuseremail" className="form-control" value={profile.tableuseremail || ''} disabled/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableuserfullname" className="form-label">{MultiLanguage('tableuserfullname')}</label>
                            <div className="col">
                                <input type="text" name="tableuserfullname" disabled={ResetFormProfile === 'full_name' ? '' : 'disabled'} className="form-control" onChange={(e) => setTableuserfullname(e.target.value)} value={tableuserfullname || ''}/>
                            </div>
                            {/* <div className="col-2 text-end">
                                {ResetFormProfile === 'full_name' ? 
                                <button type="button" className="btn btn-success form-control" onClick={saveHandle}>Save</button>
                                :
                                <button type="button" className="btn btn-primary form-control" onClick={fullNameEdit}>Edit</button>
                                }
                            </div> */}
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableusername" className="form-label">{MultiLanguage('tableusername')}</label>
                            <div className="col-sm">
                                <input type="text" name="tableusername" disabled={ResetFormProfile === 'username' ? '' : 'disabled'} className="form-control" onChange={(e) => setTableusername(e.target.value)} value={tableusername || ''}/>
                            </div>
                            <div className="col-sm-2 text-end">
                                {ResetFormProfile === 'username' ? 
                                <button type="button" className="btn btn-success form-control" onClick={saveHandleConfirmation}>{MultiLanguage('BTN_SAVE')}</button>
                                :
                                <button type="button" className="btn btn-primary form-control" onClick={usernameEdit}>{MultiLanguage('BTN_EDIT')}</button>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="tableusername" className="form-label">{MultiLanguage('tableuserphonenumber')}</label>
                            <div className="col-sm">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <select name="phonecode" className="form-control phonecode" value={tableuserphonecountrycode} disabled={ResetFormProfile === 'phonenumber' ? '' : 'disabled'} onChange={(e) => settableuserphonecountrycode(e.target.value)}>
                                            {resultArrPhoneCode}
                                        </select>
                                    </div>
                                    <input type="text" className="form-control" placeholder="21XXXXXXXXX" disabled={ResetFormProfile === 'phonenumber' ? '' : 'disabled'} value={tableuserphonenumbershort || ''} onChange={(e) => settableuserphonenumbershort(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-sm-2 text-end">
                                {ResetFormProfile === 'phonenumber' ? 
                                <button type="button" className="btn btn-success form-control" onClick={saveHandleConfirmation}>{MultiLanguage('BTN_SAVE')}</button>
                                :
                                <button type="button" className="btn btn-primary form-control" onClick={phonenumberedit}>{MultiLanguage('BTN_EDIT')}</button>
                                }
                            </div>
                            {profile.tableuserphonenumberisverified === 0 ? 
                            <span className="text-primary text-decaration pointer" onClick={requestOtpHandle}>{MultiLanguage('BTN_VERIFICATION_PHONE')}</span> : '' }
                        </div> </> : <img src={process.env.PUBLIC_URL+'/images/components/loadbars.gif'} width="100%" height="150px"/> }
                        {/* <div className="row mb-3">
                            <label htmlFor="userpinnew" className="form-label">{MultiLanguage('tableuser2fapinpin')}</label>
                            <div className="col-sm">
                                <input type="password" name="userpinnew" disabled={ResetFormProfile === 'resetpin' ? '' : 'disabled'} className="form-control" onChange={(e) => setUserPinNew(e.target.value)} placeholder="Create your PIN." value={userPinNew}/>
                            </div>
                            <div className="col-sm-2 text-end">
                                {ResetFormProfile === 'resetpin' ? 
                                <button type="button" className="btn btn-success form-control" onClick={saveHandle}>Save</button>
                                :
                                <button type="button" className="btn btn-warning form-control" onClick={createpin}>Create</button>
                                }
                            </div>
                        </div> */}
                    </div>
                    <div className="pt-4">
                        {FooterProfile()}
                    </div>
                    <ModalPassword savehandleconfirmation={saveHandleConfirmation}
                    show={modalPassword} onHide={() => {
                        dispatch({
                            type : 'MODAL_PASSWORD',
                            newModalPassword : false
                        });

                        dispatch({
                            type : 'RESET_FORM_PROFILE',
                            newResetFormProfile : ''
                        });
                    }}
                    />

                    <ModalOTP 
                        show={modalOtp} 
                        onHide={() => setModalOtp(false)}
                        executeotp={validateOtpHandle}
                        error={errorOtp}
                    />
                </div>
            </div>
        </>
    );
}

export default Profile;