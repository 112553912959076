import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Navbar from "../layout/Navbar";
import { useEffect } from 'react';
import { MultiLanguage } from '../lang/Language';

const Success = () => {
    const pageSuccess = useSelector(state => state.pageSuccess);
    const navigate = useNavigate();

    useEffect(() => {
        if(pageSuccess === ''){
            navigate('/');
        }
    }, [pageSuccess]);

    return(
        <>
            <div className="application">
                <Navbar/>
                { pageSuccess === 'registrationSuccess' ? 
                <div className="container mt-100">
                    <div className="card bg-success w-404 d-block m-auto">
                        <div className="card-body">
                            <div className="img-success mt-5">
                                <img src={process.env.PUBLIC_URL+'/images/components/register-success.png'} alt="register-success.png"/>
                            </div>
                            <div className="des-success mb-5">
                                <h3>Success Registration</h3>
                                <p>Our system just sent an email to set initial password. Please check your inbox email.</p>
                            </div>
                        </div>
                    </div>
                </div> : null}

                { pageSuccess === 'emailSuccess' ? 
                <div className="container mt-100">
                    <div className="card bg-primary w-404 d-block m-auto">
                        <div className="card-body">
                            <div className="img-success mt-5">
                                <img src={process.env.PUBLIC_URL+'/images/components/info-icon.png'} alt="new-password-success.png"/>
                            </div>
                            <div className="des-success mt-3">
                                {/* <h3>Warning</h3> */}
                                <h3>{MultiLanguage('TITLE') }</h3>
                                {/* <p>If the email is registered we will send a link to your email</p> */}
                                <p>{MultiLanguage('DESCRIPTION_TITLE_SUCCESS')}</p>
                            </div>
                        </div>
                    </div>
                </div> : null }

                { pageSuccess === 'passwordSuccess' ? 
                <div className="container mt-100">
                    <div className="card bg-success w-404 d-block m-auto">
                        <div className="card-body">
                            <div className="img-success mt-5">
                                <img src={process.env.PUBLIC_URL+'/images/components/new-password-success.png'} alt="new-password-success.png"/>
                            </div>
                            <div className="des-success">
                                <h3>Success</h3>
                                <p>Create new password</p>
                            </div>
                            <div className="d-block text-center mb-5">
                                <Link to={`/login`} className="btn btn-primary">Go to login</Link>
                            </div>
                        </div>
                    </div>
                </div> : null }
            </div>
        </>
    );
}

export default Success;