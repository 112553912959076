import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalInformation = (props) => {
    const buttonDetail = () => {
        if(props.errorCode !== 'Transaction process...'){
            if(props.description === '888999999443'){
                return <Button className="btn btn-danger w-150 w-100" onClick={() => window.location.href = "/topup"}>Close</Button>
            }
            else if(props.description === '888005200015'){
                return <Button className="btn btn-warning w-150 w-100" onClick={() => window.location.href = "/profile"}>Create PIN</Button>
            }
            else{
                return <Button className="btn btn-danger w-150 w-100" onClick={props.onHide}>Close</Button>
            }
        }else{
            return(
                <button className="btn btn-primary w-100" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="visually-hidden">Loading...</span>
                </button>
            );
        }
    }
    return(
        <>
            <Modal show={props.show} onHide={props.onHide}>
            <Modal.Body className="pt-5 ps-5 pe-5">
                <h4 className="text-center pb-3">{props.errorCode}</h4>
                <p className="text-center">{props.description}</p>
            </Modal.Body>
            <Modal.Footer className="border-top-0 pl-44 pr-44 pb-5" style={{display: 'list-item', listStyle: 'none'}}>
                {buttonDetail()}
            </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalInformation;