import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import SweetPagination from "sweetpagination";
import cryptoJS from 'crypto-js';
import axios from "axios";
import { MultiLanguage } from "../../lang/Language";

// const simpledata = JSON.stringify([
//     {
//         'historydata' : 'Notification 1',
//         'date' : '20:00 12/12/2022'
//     },
//     {
//         'historydata' : 'Notification 2',
//         'date' : '20:00 12/12/2022'
//     },
//     {
//         'historydata' : 'Notification 3',
//         'date' : '20:00 12/12/2022'
//     },
//     {
//         'historydata' : 'Notification 4',
//         'date' : '20:00 12/12/2022'
//     },
//     {
//         'historydata' : 'Notification 5',
//         'date' : '20:00 12/12/2022'
//     },
//     {
//         'historydata' : 'Notification 6',
//         'date' : '20:00 12/12/2022'
//     },
// ]);

const Notification = () => {
    const [currentPageData, setCurrentPageData] = useState([]);
    const [dataSimple, setDatSimple] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'GET',
            url : process.env.REACT_APP_APIHOST+'/api/activity/v01/lists',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature
            }
        }).then(res => {
            if(res.data.status.status === 1){
                setDatSimple(res.data.data);
            }
        });
    }, [])

    useEffect(() => {
        dispatch({
            type : 'BOX_NOTIFICATION',
            newNavNotification : false
        });
    }, []);

    return(
        <>
            <div className="application">
                <Navbar/>
                <Sidebar/>
                <div className="content">
                    <h3 className="text-center text-primary">{MultiLanguage('NOTIFICATION_LIST')}</h3>
                    { dataSimple.length > 0 ? <>
                    <div className="mt-5 transaction shadow">
                        <table id="transaction-history">
                            <tr>
                                <th>{MultiLanguage('TABLE_HISTORY_NOTIFICATION')}</th>
                                <th>{MultiLanguage('TABLE_DATE_NOTIFICATION')}</th>
                            </tr>
                            {currentPageData.map((item, key) => (
                                <tr key={key}>
                                    <td>{item.action}</td>
                                    <td width={150} className="text-primary">
                                        {new Date(item.datetime).toLocaleDateString()}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                    <div className="mt-5">
                        <SweetPagination
                            currentPageData={setCurrentPageData}
                            getData={dataSimple}
                            dataPerPage={5}
                            navigation={true}
                            getStyle={'style-2'}
                        />
                    </div>
                    </> : <div className="mt-5 transaction shadow"><img src={process.env.PUBLIC_URL+'/images/components/loadbars.gif'} width="100%" height="150px"/></div>}
                </div>
            </div>
        </>
    );
}

export default Notification;