import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalPassword = (props) => {
    const [userPassword, setUserPassword] = useState('');
    const [eye, setEye] = useState(false);

    const saveConfirm = () => {
        props.savehandleconfirmation(userPassword);
    }

    const eyeHandle = () => {
        if(eye){
            setEye(false);
        }else{
            setEye(true);
        }
    }

    return(
        <>
            <Modal show={props.show} onHide={props.onHide}>
            <Modal.Body className="pt-5 ps-5 pe-5">
                <h4 className="text-center pb-3">Security</h4>
                <div className="input-group">
                    <input type={eye ? 'text' : 'password'} className="form-control text-center border-right-none" name="userpassword" autoComplete="off" onChange={(e) => setUserPassword(e.target.value)} placeholder="Password"/>
                    <span className="input-group-text bg-none pointer" onClick={eyeHandle}>
                        {eye ? 
                        <img src={process.env.PUBLIC_URL+'/images/components/eye.png'} alt="eye.png"></img>:
                        <img src={process.env.PUBLIC_URL+'/images/components/eye-slash.png'} alt="eye-slash.png"></img> }
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0 pl-44 pr-44 pb-5">
                <Button className="btn btn-primary w-150" onClick={saveConfirm} style={{float: 'left'}}>Confirm</Button>
                <Button className="btn btn-danger w-150" onClick={props.onHide} style={{float: 'right'}}>Close</Button>
            </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalPassword;