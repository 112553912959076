import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { MultiLanguage } from "../lang/Language";

const Sidebar = () => {
    const xdwbalance = useSelector(state => state.xdwbalance);

    const barsCloseHandle = () => {
        document.getElementById("sidebar").style.display = "none";
    }
    return(
        <>
            <div className="sidebar" id="sidebar">
                <div className="brand">
                    <Link to={'/home'}>
                        <img src={process.env.PUBLIC_URL+'/images/components/237567@2x.png'} alt="brand" className="imgBrand"></img>
                    </Link>
                    <FontAwesomeIcon icon={faClose} size="2x" className="btnClose" onClick={barsCloseHandle}/>
                </div>
                <div className="main-menu">
                    <ul className="menus-sub">
                        <li className="list-menus active-menu">
                            <Link to={`/home`} className="text-decoration-none">
                                <div data-bs-target="#" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    <img src={process.env.PUBLIC_URL+'/images/components/grid-fill.png'} alt="grid-fill.png"/>
                                    <span className="ps-2 font-weight-700">{MultiLanguage('DASHBOARD_MENU')}</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                    
                    {/* <ul className="menus-sub">
                        <li className="list-menus">
                            <div data-bs-target="#" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <img src={process.env.PUBLIC_URL+'/images/components/star-fill.png'} alt="start-fill.png"/>
                                <span className="ps-2 font-weight-700">Launchpad Whatchlist</span>
                            </div>
                        </li>
                    </ul>
                    <ul className="menus-sub">
                        <li className="list-menus">
                            <div data-bs-target="#" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <img src={process.env.PUBLIC_URL+'/images/components/star-fill.png'} alt="start-fill.png"/>
                                <span className="ps-2 font-weight-700">Presale Whatchlist</span>
                            </div>
                        </li>
                    </ul>
                    <ul className="menus-sub">
                        <li className="list-menus">
                            <div data-bs-target="#launchpad" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <img src={process.env.PUBLIC_URL+'/images/components/launchpad.png'} alt="launchpad.png"/>
                                <span className="ps-2 font-weight-700">Launchpad</span>
                                <FontAwesomeIcon icon={faAngleDown} className="float-end pt-1"/>
                            </div>
                        </li>
                        <ul className="collapse show" id="launchpad">
                            <li className="sub-menu-list active-menu">Create Launchpad</li>
                            <li className="sub-menu-list">My Launchpad</li>
                            <li className="sub-menu-list">Explore Launchpad</li>
                        </ul>
                    </ul>

                    <ul className="menus-sub">
                        <li className="list-menus">
                            <div data-bs-target="#airdrop" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <img src={process.env.PUBLIC_URL+'/images/components/airdrop.png'} alt="airdrop.png"/>
                                <span className="ps-2 font-weight-700">Airdrop</span>
                                <FontAwesomeIcon icon={faAngleDown} className="float-end pt-1"/>
                            </div>
                        </li>
                        <ul className="collapse" id="airdrop">
                            <li className="sub-menu-list">Create Airdrop</li>
                            <li className="sub-menu-list">My Airdrop</li>
                            <li className="sub-menu-list">Explore Airdrop</li>
                        </ul>
                    </ul>

                    <ul className="menus-sub">
                        <li className="list-menus">
                            <div data-bs-target="#privatesale" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <img src={process.env.PUBLIC_URL+'/images/components/shield-fill.png'} alt="shield-fill.png"/>
                                <span className="ps-2 font-weight-700">Private Sale</span>
                                <FontAwesomeIcon icon={faAngleDown} className="float-end pt-1"/>
                            </div>
                        </li>
                        <ul className="collapse" id="privatesale">
                            <li className="sub-menu-list">Create Private Sale</li>
                            <li className="sub-menu-list">My Private Sale</li>
                            <li className="sub-menu-list">Explore Private Sale</li>
                        </ul>
                    </ul>

                    <ul className="menus-sub mbc-6rem">
                        <li className="list-menus">
                            <div data-bs-target="#presale" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <img src={process.env.PUBLIC_URL+'/images/components/cart-plus-fill.png'} alt="cart-plus-fill.png"/>
                                <span className="ps-2 font-weight-700">Pre Sale</span>
                                <FontAwesomeIcon icon={faAngleDown} className="float-end pt-1"/>
                            </div>
                        </li>
                        <ul className="collapse" id="presale">
                            <li className="sub-menu-list">Create Pre Sale</li>
                            <li className="sub-menu-list">My Pre Sale</li>
                            <li className="sub-menu-list">Explore Pre Sale</li>
                        </ul>
                    </ul> */}

                    <div className="footer-balance">
                        <img src={process.env.PUBLIC_URL+'/images/components/237567@2x.png'} alt="brand" width={100}></img>
                        <span className="side-balance">{xdwbalance} XDW</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;