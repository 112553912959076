import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ProgressTopupDwa = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/topupdwa/v01/process',
            data : {
                emailtrxcode : id,
            }
        }).then(res => {
            if(res.data.status.status !== 1){
                return navigate('/');
            }
        });
    }, [id])
    return(
        <>
            <div className="progresstopupdwa">
                <div className="alert alert-primary" role="alert">
                    Your topup request will be processed in the next few minutes. Thank you
                </div>

                <p className="pt-4">
                    <span className="bg-primary text-white p-1">ATTENTION</span> Crypto asset trading is a high risk activity. Crypto asset prices are very volatile, where prices can change significantly from time to time. Please use extra consideration in making a decision to buy or sell crypto assets. XDW does not force users to buy or sell crypto assets, as an investment, or for profit. All crypto trading decisions are independent decisions by users.
                </p>
            </div>
        </>
    );
}

export default ProgressTopupDwa;